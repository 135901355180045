import { GalleryCategory } from "~/ts/interfaces/Gallery";

export class TextUtil {
    
    public static galleryTypeToText(type: string): string {
        switch (type) {
            case GalleryCategory.ACTIVITY:
                return 'Aktivität';
            case GalleryCategory.EVENT:
                return 'Event';
            case GalleryCategory.CAMP:
                return 'Lager';
            case GalleryCategory.COURSE:
                return 'Kurs';
            default:
                return 'Unbekannt'
        }
    }
}