import type { Activity } from "./Activity";
import type { StrapiDataItem } from "./strapi/StrapiData";
import type { StrapiMedia } from "./strapi/StrapiMedia";

export enum GalleryCategory {
    ACTIVITY = "activity",
    CAMP = "camp",
    EVENT = "event",
    COURSE = "course",
}

export interface GalleryHelper {
    isExpanded: boolean,
}


export interface Gallery extends StrapiDataItem {
    id: number;
    title: string;
    category: GalleryCategory | null;
    YouTubeUrl: string | null;

    images: StrapiMedia[];

    activity: Activity | null;
}

export interface GallerySearchValues {
    title?: string;
    category?: string[];
    agegroups?: number[];
    dateAfter?: string;
    dateBefore?: string;
}