<script setup lang="ts">
import type { Gallery } from '~/ts/interfaces/Gallery';
import { ImageUtil } from '~/util/ImageUtil';
import { TextUtil } from '~/util/TextUtil';

const config = useRuntimeConfig();

const props = defineProps({
    gallery: {
        type: Object as PropType<Gallery>,
        required: true,
    },
});
props.gallery.images?.sort((a, b) => {
    return a.name.localeCompare(b.name);
});

function formatDate(date: string) {
    const d = new Date(date);
    return d.toLocaleDateString('de-CH');
}
</script>

<template>
    <div class="gallery-card">
        <div class="gallery-card__cover">
            <div class="gallery-card__cover__badges">
                <span class="gallery-card__cover__badges__item">{{
                    TextUtil.galleryTypeToText(gallery.category ??
                        '') }}</span>
            </div>
            <div class="gallery-card__cover__images"
                v-if="gallery.images && gallery.images.length">
                <div id="gallery-card__image--one" class="gallery-card__cover__images__image"
                    :style="'background-image: url(' + config.public.apiUrl + ImageUtil.getImageFormatURL(gallery.images[0], 'medium') + ');'">
                </div>
                <div id="gallery-card__image--two" class="gallery-card__cover__images__image" :style="'background-image: url(' + config.public.apiUrl + ImageUtil.getImageFormatURL(gallery.images[1], 'small') + ');'
                    ">
                </div>
                <div id="gallery-card__image--three" class="gallery-card__cover__images__image" :style="'background-image: url(' + config.public.apiUrl + ImageUtil.getImageFormatURL(gallery.images[2], 'small') + ');'
                    ">
                </div>
            </div>
        </div>
        <div class="gallery-card__content">
            <div class="gallery-card__content__details">
                <h3 class="gallery-card__content__details__title">
                    {{ gallery.title }}
                </h3>
                <div class="gallery-card__content__details__stats">
                    <span>
                        <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;{{ gallery?.activity ?? gallery.activity?.location != 'NULL' ? gallery.activity?.location : 'Location unkown' }}</span>
                    <span class="stats-separator">·</span>
                    <span> {{ gallery?.activity ?? gallery.activity?.start_date ?
                        formatDate(gallery.activity.start_date) : 'Date unkown' }}</span>
                    <span class="stats-separator">·</span>
                    <span>
                        <i class="fa fa-pictures" aria-hidden="true"></i>&nbsp; Bilder:
                        {{ gallery.images?.length }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
$border-radius: 5px;

.gallery-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
        transform: scale(1.015);
    }

    &__cover {
        position: relative;
        max-width: 380px;
        width: 380px;
        height: 300px;

        &__badges {
            position: absolute;
            display: flex;
            top: 0;
            left: 0;
            z-index: 5;
            margin: 8px;

            &__item {
                background-color: rgba($color: #fff, $alpha: 0.7);
                color: black;
                padding: 0.1rem 0.4rem;
                border-radius: 10rem
            }
        }

        &__images {
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 2px;
            height: 100%;
            width: 100%;

            &__image {
                background-color: #e1e1e1;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            #gallery-card__image--one {
                grid-column: 1 / span 6;
                grid-row: 1 / span 2;
                // ToDo: Make borders on parent
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }

            #gallery-card__image--two {
                grid-column: 7 / span 4;
                grid-row: 1 / span 1;
                border-top-right-radius: $border-radius;
            }

            #gallery-card__image--three {
                grid-column: 7 / span 4;
                grid-row: 2 / span 1;
                border-bottom-right-radius: $border-radius;
            }
        }
    }

    &__content {
        padding: 10px;
        padding-left: 15px;
        height: max-content;

        &__details {
            color: rgb(33, 33, 36);
            display: flex;
            flex-direction: column;
            height: max-content;
            width: 100%;
            justify-content: center;

            &__title {
                display: block;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                overflow-x: hidden;
                overflow-y: hidden;
                text-overflow: ellipsis;
                // white-space: nowrap;
                -webkit-box-direction: normal;
            }

            &__stats {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: rgb(179, 179, 179);
                display: block;
                font-size: 14px;
                text-align: center;
                height: 20px;
                line-height: 14px;
                padding-top: 3px;
                overflow-x: hidden;
                overflow-y: hidden;
                white-space: nowrap;

                .stats-separator {
                    font-weight: 800;
                    height: auto;
                    margin-bottom: 0px;
                    margin-left: 6px;
                    margin-right: 6px;
                    margin-top: 0px;
                    white-space: nowrap;
                    width: auto;
                    -webkit-box-direction: normal;
                }
            }
        }
    }

    @media screen and (max-width: 425px) {
        &__cover {
            width: 100%;
        }
    }
}
</style>